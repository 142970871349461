import React, { Fragment } from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";

import { Helmet } from "react-helmet";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

import withRoot from "../../withRoot";
import Header from "../../component/header";

const PostLink = ({ post }) => (
  <Link to={post.fields.slug}>
    {post.frontmatter.date} - {post.frontmatter.title} (
    {post.fields.readingTime.text})
  </Link>
);

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges }
  },
  classes
}) => {
  const Posts = edges
    .filter(
      edge =>
        edge.node.frontmatter.tags && edge.node.frontmatter.tags === "blog"
    )
    .map(edge => (
      <Fragment key={edge.node.id}>
        <PostLink post={edge.node} />
        <br />
      </Fragment>
    ));

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog - Sébastien Viande - Developper FullStack</title>
        <meta name="description" content="Personal website, portfolio, blog." />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
      </Helmet>
      <Header />
      <Paper id="main" className={classes.main}>
        <Typography color="primary" align="center" variant="h1" gutterBottom>
          Blog articles
        </Typography>
        <section className={classes.section}>{Posts}</section>
      </Paper>
    </Fragment>
  );
};

const styles = ({ spacing, breakpoints }) => ({
  main: {
    position: "relative",
    marginTop: "-5rem",
    marginBottom: "5rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    [breakpoints.up("md")]: {
      marginLeft: "auto",
      marginRight: "auto"
    },
    padding: spacing(2),
    maxWidth: 800
  },
  info: {
    color: "grey",
    marginBottom: "2rem"
  },
  section: {
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    "& img": {
      maxWidth: "90%",
      marginLeft: "auto",
      marginRight: "auto"
    }
  }
});

export default withRoot(withStyles(styles)(IndexPage));

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          fields {
            slug
            readingTime {
              text
            }
          }
          excerpt(pruneLength: 250)
          frontmatter {
            tags
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`;
